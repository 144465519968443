import React from 'react';
import { useNavigate } from 'react-router-dom';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ComputerIcon from '@mui/icons-material/Computer';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import InsightsIcon from '@mui/icons-material/Insights';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useInterval } from '@tzmedical/react-hooks';

import axios from '../axiosClient.js';
import Alert from './common/Alert.jsx';

const defaultGroups = [
  {
    name: 'Admin',
    icon: <LocalLibraryIcon />,
    callsUrl: '/calls/Admin',
    goalsUrl: '/goals/1',
    SelectedView: 'calls',
    Id: 1,
  },
  {
    name: 'Mechanicals',
    icon: <PrecisionManufacturingIcon />,
    callsUrl: '/calls/Mechanicals',
    goalsUrl: '/goals/2',
    SelectedView: 'calls',
    Id: 2,
  },
  {
    name: 'CSR',
    icon: <SupportAgentIcon />,
    callsUrl: '/calls/CSR',
    goalsUrl: '/goals/3',
    SelectedView: 'calls',
    Id: 3,
  },
  {
    name: 'SDR',
    icon: <DevicesOtherIcon />,
    callsUrl: '/calls/SDR',
    goalsUrl: '/goals/4',
    SelectedView: 'calls',
    Id: 4,
  },
  {
    name: 'IT',
    icon: <ComputerIcon />,
    callsUrl: '/calls/IT',
    goalsUrl: '/goals/5',
    SelectedView: 'calls',
    Id: 5,
  },
  {
    name: 'Sales Interns',
    icon: <AttachMoneyIcon />,
    goalsUrl: '/goals/6',
    SelectedView: 'goals',
    Id: 6,
  },
];

const DATA_REFRESH_INTERVAL_MS = 3_600_000; // one hour

function SideBar({ onClick }) {
  const [groups, setGroups] = React.useState(defaultGroups);
  const [version, setVersion] = React.useState('');
  const [error, setError] = React.useState(null);
  const navigate = useNavigate();

  const fetchItems = React.useCallback(async () => {
    try {
      const [groupsQuery, versionQuery] = await Promise.all([
        axios({ method: 'GET', url: '/api/groups' }),
        axios({ method: 'GET', url: '/api/version' }),
      ]);

      const newGroupList = groupsQuery.data
        .filter((newGroup) => !defaultGroups.some(({ name }) => name === newGroup.Name))
        .map((group) => ({
          name: group.Name,
          callsUrl: `/calls/${encodeURIComponent(group.Id)}`,
          goalsUrl: `/goals/${encodeURIComponent(group.Id)}`,
          icon: <InsightsIcon />,
          selectedView: group.SelectedView,
          Id: group.Id,
        }));

      setGroups([...defaultGroups, ...newGroupList]);
      setVersion(versionQuery.data.version);
      setError(null);
    } catch (err) {
      setError(
        err.response?.data?.message ||
          err.response?.data ||
          err.message ||
          'Something bad happened...'
      );
    }
  }, []);

  const handleClick = React.useCallback(
    async (group) => {
      try {
        const { data: groupsQuery } = await axios({ method: 'GET', url: '/api/groups' });

        const selectedGroup = groupsQuery.find((g) => g.Id === group.Id);

        const selectedView = selectedGroup.SelectedView;

        const url = selectedView === 'goals' ? group.goalsUrl : group.callsUrl;

        navigate(url);
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      }
    },
    [navigate]
  );

  useInterval(fetchItems, DATA_REFRESH_INTERVAL_MS, true);

  return (
    <>
      <Stack justifyContent="space-between" height="100%">
        <List onClick={onClick}>
          {groups.length ? (
            <List disablePadding>
              {groups.map((group) => (
                <ListItemButton key={group.name} onClick={() => handleClick(group)}>
                  <ListItemIcon>{group.icon}</ListItemIcon>
                  <ListItemText primary={group.name} />
                </ListItemButton>
              ))}
            </List>
          ) : (
            <CircularProgress />
          )}
        </List>
        <Typography
          data-cy="version-number"
          sx={{
            borderRadius: 2,
            fontSize: '0.75rem',
            opacity: 0.7,
            px: 2,
            width: 'fit-content',
          }}
        >
          v{version}
        </Typography>
      </Stack>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
    </>
  );
}

export default SideBar;
