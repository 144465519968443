import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';

import PageLoading from './components/common/PageLoading.jsx';
import Layout from './components/Layout.jsx';
import useCustomTheme from './hooks/customizeReactTheme.jsx';
import useAuth0Roles from './hooks/useAuth0Roles.js';
import CallCounts from './pages/CallCounts/index.jsx';
import Dashboard from './pages/Dashboard/index.jsx';
import GoalsPage from './pages/Goals/index.jsx';
import Page404 from './pages/Page404.jsx';
import Settings from './pages/Settings/index.jsx';
import { addAccessTokenInterceptor } from './axiosClient.js';

function App() {
  const { isLoading, isAuthenticated, error, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();

  const { isAdmin } = useAuth0Roles();
  const theme = useCustomTheme();

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      // Use pathname for redirect - since we're staying within the application
      loginWithRedirect({
        appState: { returnTo: `${window.location.pathname}${window.location.search}` },
      });
    }
  }, [isLoading, isAuthenticated, error, loginWithRedirect]);

  React.useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {error && (
          <Container maxWidth="lg">
            <>Oops... {error?.message || 'Something Went Wrong'}</>
            <Button
              onClick={() =>
                loginWithRedirect({
                  appState: { returnTo: window.location.origin },
                })
              }
            >
              Login
            </Button>
          </Container>
        )}
        {!error && (isLoading || !isAuthenticated) && <PageLoading />}
        {isAuthenticated && (
          <Layout>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/calls/:group" element={<CallCounts />} />
              <Route path="/goals/:groupId" element={<GoalsPage />} />
              {isAdmin && <Route path="/settings" element={<Settings />} />}
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Layout>
        )}
      </ThemeProvider>
    </div>
  );
}

export default App;
