import React from 'react';
import { useForm } from 'react-hook-form';

import AddPersonIcon from '@mui/icons-material/PersonAdd';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import axios from '../axiosClient.js';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import FormStringInput from './common/FormStringInput.jsx';

function AddMember({ group, refreshData }) {
  const [error, setError] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const defaultValues = React.useMemo(
    () => ({ selectedUsers: group.Users.map(({ Id }) => Id) }),
    [group.Users]
  );

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm(defaultValues);

  const handleClose = React.useCallback(() => setOpen(false), []);
  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleUsers = React.useCallback(async () => {
    const { data } = await axios({
      method: 'GET',
      url: '/api/users/',
    });
    setUsers(data.map((user) => ({ name: `${user.FirstName} ${user.LastName}`, id: user.Id })));
  }, []);

  const onSubmit = React.useCallback(
    async (values) => {
      setSubmitLoading(true);
      const { selectedUsers } = values;

      try {
        await axios({
          method: 'PUT',
          url: `/api/groups/${group.Id}/users`,
          data: { users: selectedUsers },
        });
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      }

      reset();
      setSubmitLoading(false);
      setOpen(false);
      if (refreshData) {
        refreshData();
      }
    },
    [group.Id, refreshData, reset]
  );

  React.useEffect(() => {
    handleUsers();
  }, [handleUsers]);

  return (
    <>
      <Button onClick={handleOpen} startIcon={<AddPersonIcon />}>
        Add Users
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitle sx={{ textTransform: 'capitalize' }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <AddPersonIcon />
            Add Member: {group.Name}
          </Stack>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent>
            <DialogContentText>
              Update the list of users displayed in the {group.Name} group
            </DialogContentText>
            <FormStringInput
              control={control}
              label="Group"
              name="selectedUsers"
              defaultValue={defaultValues.selectedUsers}
              options={users}
              selectMultiple
            />
          </DialogContent>
          <DialogActions>
            <Box m={2}>
              <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
                Cancel
              </CancelButton>
            </Box>
            <Box m={2}>
              <LoadingButton
                id={`${group.Name}-add-group-submit`}
                variant="contained"
                color="secondary"
                loading={submitLoading}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default AddMember;
