import React from 'react';
import { useForm } from 'react-hook-form';

import ColumnIcon from '@mui/icons-material/ViewColumn';

import LoadingButton from '@mui/lab/LoadingButton';
import WarningAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import axios from '../axiosClient.js';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import FormStringInput from './common/FormStringInput.jsx';

const bobAnimation = {
  animation: 'bobUpDown 5s ease infinite',
  position: 'relative',
};

function ColumnPicker({ group, timeIntervals, refreshData }) {
  const [error, setError] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const memoizedTimeIntervals = React.useMemo(
    () => timeIntervals.map((interval) => ({ name: interval.Name, id: interval.Id })),
    [timeIntervals]
  );

  const defaultValues = React.useMemo(
    () => ({
      columns: group.TimeIntervals.map(
        (interval) => timeIntervals.find(({ Name }) => Name === interval.Name).Id
      ),
    }),
    [group.TimeIntervals, timeIntervals]
  );
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
    watch,
  } = useForm(defaultValues);
  const handleOpen = React.useCallback(() => setOpen(true), [setOpen]);
  const handleClose = React.useCallback(() => setOpen(false), [setOpen]);

  const onSubmit = React.useCallback(
    async (values) => {
      setSubmitLoading(true);
      const { columns } = values;

      try {
        await axios({
          method: 'PUT',
          url: `/api/groups/${group.Id}`,
          data: { columns },
        });

        reset();
        setSubmitLoading(false);
        setOpen(false);
        if (refreshData) {
          refreshData();
        }
      } catch (err) {
        setError(err.response?.data?.message || err.message);
        setSubmitLoading(false);
      }
    },
    [group.Id, refreshData, reset]
  );

  return (
    <>
      <Button onClick={handleOpen} startIcon={<ColumnIcon />}>
        Change Calls Columns
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitle sx={{ textTransform: 'capitalize' }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <ColumnIcon />
            Column Picker: {group.Name}
          </Stack>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent>
            {watch('columns')?.length > 3 && (
              <WarningAlert severity="warning" sx={bobAnimation}>
                <AlertTitle>Caution!</AlertTitle>
                Selecting more than four columns per group may create unexpected behavior, select at
                your own risk!
              </WarningAlert>
            )}
            <DialogContentText pb={2}>
              Define the default columns to be viewed by members with access to the {group.Name}{' '}
              group
            </DialogContentText>
            <FormStringInput
              control={control}
              label="Columns To View"
              name="columns"
              defaultValue={defaultValues.columns}
              options={memoizedTimeIntervals}
              selectMultiple
            />
          </DialogContent>
          <DialogActions>
            <Box m={2}>
              <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
                Cancel
              </CancelButton>
            </Box>
            <Box m={2}>
              <LoadingButton
                id={`${group.Name}-group-pref-submit`}
                variant="contained"
                color="secondary"
                loading={submitLoading}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default ColumnPicker;
