import React from 'react';

import { useTheme } from '@mui/material/styles';

const getColor = (percentage, theme) => {
  const transitions = [
    { from: theme.palette.error.dark, to: theme.palette.error.light, range: [0, 25] },
    { from: theme.palette.error.light, to: theme.palette.warning.light, range: [25, 50] },
    { from: theme.palette.warning.light, to: 'rgb(255, 220, 0)', range: [50, 75] },
    { from: 'rgb(255, 220, 0)', to: theme.palette.success.light, range: [75, 100] },
    { from: theme.palette.success.light, to: theme.palette.info.light, range: [100, 150] },
    { from: theme.palette.info.light, to: theme.palette.info.main, range: [150, 200] },
  ];

  const parseColorCode = (colorCode) => {
    if (colorCode.startsWith('#')) {
      const red = colorCode.slice(1, 3);
      const green = colorCode.slice(3, 5);
      const blue = colorCode.slice(5, 7);
      return { red: parseInt(red, 16), green: parseInt(green, 16), blue: parseInt(blue, 16) };
    }
    if (colorCode.startsWith('rgb')) {
      const [red, green, blue] = colorCode.match(/\d+/g).map(Number);
      return { red, green, blue };
    }
    throw new Error('Unsupported color format');
  };

  const interpolateColor = (color1, color2, factor) => {
    const { red: r1, green: g1, blue: b1 } = parseColorCode(color1);
    const { red: r2, green: g2, blue: b2 } = parseColorCode(color2);
    const r = Math.floor(r1 + factor * (r2 - r1));
    const g = Math.floor(g1 + factor * (g2 - g1));
    const b = Math.floor(b1 + factor * (b2 - b1));
    return `rgb(${r}, ${g}, ${b})`;
  };

  const transition = transitions.find(
    ({ range }) => percentage >= range[0] && percentage <= range[1]
  );
  if (transition) {
    const { from, to, range } = transition;
    const factor = (percentage - range[0]) / (range[1] - range[0]);
    return interpolateColor(from, to, factor);
  }
  // Default if percentage > 200
  return theme.palette.info.main;
};

function ColoredProgressBar({ x, y, width, height, value, payload }) {
  const theme = useTheme();
  const percentage = React.useMemo(() => (value / payload.goal) * 100, [payload.goal, value]);
  const fillColor = React.useMemo(() => getColor(percentage, theme), [percentage, theme]);
  return <rect x={x} y={y} width={width} height={height} fill={fillColor} />;
}

export default React.memo(ColoredProgressBar);
