import React from 'react';

import Menu from '@mui/icons-material/Menu';

import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import AvatarMenu from './AvatarMenu.jsx';

function TopBar({ onClick }) {
  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Grid container spacing={3} justifyContent="space-between" alignItems="center">
          <Grid item xs="auto">
            <Stack direction="row" alignItems="center">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={onClick}
              >
                <Menu />
              </IconButton>
              <img src="/favicon.ico" alt="TZ" height="32px" />
              <Typography variant="h6" noWrap component="div" sx={{ ml: '10px' }}>
                Phone Log
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs="auto">
            <AvatarMenu />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
