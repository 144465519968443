import React from 'react';
import { useForm } from 'react-hook-form';

import AddTaskIcon from '@mui/icons-material/AddTask';
import InfoIcon from '@mui/icons-material/Info';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import axios from '../axiosClient.js';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import FormStringInput from './common/FormStringInput.jsx';

function GoalPickerForm({ group, refreshData }) {
  const [error, setError] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const defaultValues = React.useMemo(() => {
    return {
      dailyGoal: group.dailyGoal || 0,
      weeklyGoal: group.weeklyGoal || 0,
      yearlyGoal: group.yearlyGoal || 0,
    };
  }, [group]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({ defaultValues });

  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => setOpen(false), []);

  const onSubmit = React.useCallback(
    async (values) => {
      setSubmitLoading(true);
      try {
        await axios({
          method: 'PUT',
          url: `/api/goals/${group.Id}`,
          data: {
            Goal: {
              Daily: values.dailyGoal,
              Weekly: values.weeklyGoal,
              Yearly: values.yearlyGoal,
            },
          },
        });
        reset();
        setSubmitLoading(false);
        setOpen(false);
        if (refreshData) {
          refreshData();
        }
      } catch (err) {
        setError(err.response?.data?.error || err.message);
        setSubmitLoading(false);
      }
    },
    [group.Id, refreshData, reset]
  );

  return (
    <>
      <Tooltip title="Set goals for daily, weekly, and yearly intervals.">
        <Button onClick={handleOpen} startIcon={<AddTaskIcon />}>
          Set Goals
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitle>
          <Tooltip title="Set goals for daily, weekly, and yearly intervals.">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          Set Goals
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent>
            <DialogContentText pb={2}>
              <Stack direction="row" alignItems="baseline" gap={1}>
                <Typography sx={{ whiteSpace: 'nowrap' }}>
                  <strong>Set goals:</strong>
                </Typography>
                <Typography>Set goals for daily, weekly, and yearly intervals</Typography>
              </Stack>
            </DialogContentText>
            <Stack spacing={2}>
              <FormStringInput
                control={control}
                label="Daily Goal"
                name="dailyGoal"
                defaultValue={defaultValues.dailyGoal}
                type="number"
                required
              />
              <FormStringInput
                control={control}
                label="Weekly Goal"
                name="weeklyGoal"
                defaultValue={defaultValues.weeklyGoal}
                type="number"
                required
              />
              <FormStringInput
                control={control}
                label="Yearly Goal"
                name="yearlyGoal"
                defaultValue={defaultValues.yearlyGoal}
                type="number"
                required
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={2} m={2}>
              <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
                Cancel
              </CancelButton>
              <LoadingButton
                variant="contained"
                color="secondary"
                loading={submitLoading}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default GoalPickerForm;
