import React from 'react';
import {
  Bar,
  BarChart,
  LabelList,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import { useTheme } from '@mui/material/styles';

import ColoredProgressBar from './ColoredProgressBar.jsx';

function GoalGraph({ data, width, height }) {
  const theme = useTheme();

  const sortedData = data.sort((a, b) => b.callCount - a.callCount);

  const goalValue = sortedData[0]?.goal || 0;

  return (
    <ResponsiveContainer width={width} height={height} barCategoryGap="10px">
      <BarChart data={sortedData} layout="vertical">
        <YAxis
          type="category"
          dataKey="name"
          yAxisId="name"
          interval={0}
          width={70}
          fontSize="85%"
        />
        <YAxis type="category" yAxisId="goal" hide />
        <XAxis
          type="number"
          label={{
            position: 'insideTopLeft',
            value: 'Calls',
            dx: -50,
            dy: 2,
          }}
        />
        <ReferenceLine x={goalValue} stroke="red" strokeWidth={2} yAxisId="goal" />
        <Bar dataKey="goal" fill={theme.palette.action.selected} yAxisId="goal" barSize={30} />
        <Bar dataKey="callCount" shape={<ColoredProgressBar />} yAxisId="name" barSize={30}>
          <LabelList
            dataKey="callCount"
            position="right"
            style={{
              fill: theme.palette.text.secondary,
              fontWeight: 'bolder',
              fontSize: '100%',
            }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default React.memo(GoalGraph);
