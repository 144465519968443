import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';

import Masonry from '@mui/lab/Masonry';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useInterval } from '@tzmedical/react-hooks';

import axios from '../axiosClient.js';
import AddMember from './AddMember.jsx';
import ColumnPicker from './ColumnPicker.jsx';
import GoalPickerForm from './GoalPickerForm.jsx';

const DATA_REFRESH_INTERVAL_MS = 3_600_000; // one hour

function GroupSettings() {
  const [groups, setGroups] = React.useState([]);
  const [timeIntervals, setTimeIntervals] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const handleGroups = React.useCallback(async () => {
    const [groupsQuery, intervalQuery] = await Promise.all([
      axios({
        method: 'GET',
        url: '/api/groups/',
      }),
      axios({
        method: 'GET',
        url: '/api/time-interval/',
      }),
    ]);
    setGroups(
      groupsQuery.data.map((group) => ({
        memberCount: group.Users.length,
        SelectedView: group.SelectedView,
        ...group,
      }))
    );
    setTimeIntervals(intervalQuery.data);
    setLoading(false);
  }, []);

  const refreshData = React.useCallback(() => setLoading(true), []);
  useInterval(handleGroups, DATA_REFRESH_INTERVAL_MS, loading);

  useInterval(handleGroups, DATA_REFRESH_INTERVAL_MS, true);

  const handleViewChange = async (group, view) => {
    await axios.put(`/api/groups/${group.Name}/view`, { view });
    setGroups((prevGroups) =>
      prevGroups.map((g) => (g.Name === group.Name ? { ...g, SelectedView: view } : g))
    );
  };

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Masonry>
      {groups.map((group) => (
        <Card sx={{ mr: 1, mt: 1 }} key={group.Name}>
          <CardHeader
            action={
              <Stack direction="row" alignItems="center">
                <PersonIcon fontSize="inherit" color="text.secondary" />
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  {group.memberCount}
                </Typography>
              </Stack>
            }
            title={group.Name}
            sx={{ pb: 0 }}
          />
          <CardContent sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
            {group.Users.map((user) => {
              const fullName = `${user.FirstName} ${user.LastName}`;
              return <Chip key={fullName} label={fullName} variant="outlined" color="info" />;
            })}
          </CardContent>
          <CardActions>
            <Stack direction="column" spacing={2} alignItems="stretch">
              <FormControl component="fieldset">
                <FormLabel component="legend" sx={{ fontSize: 14 }}>
                  Page Configuration
                  <Tooltip title="Choose between viewing Calls or Goals for this group.">
                    <IconButton size="small">
                      <InfoIcon fontSize="small" sx={{ fontSize: '1rem' }} />
                    </IconButton>
                  </Tooltip>
                </FormLabel>

                <RadioGroup
                  row
                  value={group.SelectedView || 'calls'}
                  onChange={(e) => handleViewChange(group, e.target.value)}
                >
                  <FormControlLabel value="calls" control={<Radio />} label="Calls" />
                  <FormControlLabel value="goals" control={<Radio />} label="Goals" />
                </RadioGroup>
              </FormControl>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <AddMember group={group} refreshData={refreshData} />
              </Stack>
              <Box sx={{ pb: 2 }}>
                <Typography sx={{ fontSize: 14, ml: 1 }} color="text.secondary">
                  Column and Goal Configuration
                </Typography>
                {group.SelectedView === 'calls' && (
                  <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ mb: 1 }}>
                    <ColumnPicker
                      group={group}
                      timeIntervals={timeIntervals}
                      refreshData={refreshData}
                    />
                  </Stack>
                )}
                {group.SelectedView === 'goals' && (
                  <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ mt: 1 }}>
                    <GoalPickerForm
                      group={group}
                      timeIntervals={timeIntervals}
                      refreshData={refreshData}
                    />
                  </Stack>
                )}
              </Box>
            </Stack>
          </CardActions>
        </Card>
      ))}
    </Masonry>
  );
}

export default React.memo(GroupSettings);
