import React from 'react';
import { useParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';

import { useInterval } from '@tzmedical/react-hooks';

import axios from '../../axiosClient.js';
import GoalGraph from './GoalGraph.jsx';

const DATA_REFRESH_INTERVAL_MS = 60000;

// Mapping between database fields and call summary keys
const goalMapping = {
  Daily: 'today',
  Weekly: 'week',
  Yearly: 'year',
};

function GoalsPage() {
  const { groupId } = useParams();
  const [callSummary, setCallSummary] = React.useState({});
  const [groupName, setGroupName] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [cutoffDate, setCutoffDate] = React.useState(null);
  const [goals, setGoals] = React.useState({
    Daily: 0,
    Weekly: 0,
    Yearly: 0,
  });

  const fetchGroupName = React.useCallback(async () => {
    try {
      const response = await axios.get('/api/groups/');
      const group = response.data.find(({ Id }) => Number(Id) === Number(groupId));

      if (group) {
        setGroupName(group.Name);
      } else {
        setError('Group not found');
      }
    } catch (err) {
      setError(err.message);
    }
  }, [groupId]);

  const fetchCallSummary = React.useCallback(async () => {
    try {
      const response = await axios.get(`/api/calls/summary/${groupName}`, {
        params: {
          cutoff: cutoffDate ? new Date(cutoffDate).toISOString() : undefined,
        },
      });
      setCallSummary(response.data);
    } catch (err) {
      setError(err.message);
    }
  }, [groupName, cutoffDate]);

  const fetchGoals = React.useCallback(async () => {
    try {
      const response = await axios.get(`/api/goals/${groupId}`);
      setGoals({
        Daily: response.data.Goal.Daily,
        Weekly: response.data.Goal.Weekly,
        Yearly: response.data.Goal.Yearly,
      });
    } catch (err) {
      setError(err.message);
    }
  }, [groupId]);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchGroupName();
    };
    fetchData();
  }, [fetchGroupName]);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchCallSummary();
      await fetchGoals();
      setLoading(false);
    };
    fetchData();
  }, [fetchCallSummary, fetchGoals, cutoffDate]);

  useInterval(fetchCallSummary, DATA_REFRESH_INTERVAL_MS, loading);

  const getUserData = React.useCallback(
    (timeGroup) => {
      const timeGroupData = callSummary[goalMapping[timeGroup]] || [];
      const goal = goals[timeGroup] || 0;
      return timeGroupData.map((user) => ({
        name: `${user.FirstName}`,
        callCount: user.CallCount,
        goal,
        id: user.Id,
      }));
    },
    [callSummary, goals]
  );

  const visibleIndividualGoals = React.useMemo(
    () =>
      [
        { label: 'Daily', timeGroup: 'Daily', goal: goals.Daily },
        { label: 'Weekly', timeGroup: 'Weekly', goal: goals.Weekly },
        { label: 'Yearly', timeGroup: 'Yearly', goal: goals.Yearly },
      ].filter((goal) => goal.goal > 0),
    [goals]
  );

  const memoizedUserData = React.useMemo(() => {
    const data = {};
    visibleIndividualGoals.forEach((goal) => {
      data[goal.timeGroup] = getUserData(goal.timeGroup);
    });
    return data;
  }, [visibleIndividualGoals, getUserData]);
  const handleDateChange = React.useCallback(
    (newValue) => {
      if (newValue.invalid) {
        setCutoffDate(null);
        return;
      }
      setCutoffDate(newValue);
    },
    [setCutoffDate]
  );

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Grid container spacing={2} style={{ height: '85vh' }}>
      <Grid item xs={12} sx={{ textAlign: 'flex-start' }}>
        {Boolean(visibleIndividualGoals.length) && (
          <DatePicker
            label="Cutoff Date"
            value={cutoffDate}
            onChange={handleDateChange}
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      </Grid>
      {visibleIndividualGoals.map((goal) => (
        <Grid item xs={12} sm={6} md={12 / visibleIndividualGoals.length} key={goal.timeGroup}>
          <Card sx={{ height: '100%' }}>
            <CardHeader
              title={`${goal.label} Goal`}
              subheader={`${goal.goal} Calls`}
              sx={{ px: 1, py: 0, my: 2 }}
            />
            <CardContent style={{ height: '85%', padding: 0, marginRight: 20 }}>
              <GoalGraph data={memoizedUserData[goal.timeGroup]} height="100%" width="100%" />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default GoalsPage;
