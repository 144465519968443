import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const ROLES_CLAIM_KEY = 'https://tzmedical.io/roles';

export default function useAuth0Roles() {
  const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const [roles, setRoles] = React.useState([]);
  const [isAdmin, setIsAdmin] = React.useState(false);

  const getRoles = React.useCallback(async () => {
    const claims = await getIdTokenClaims();
    const auth0Roles = claims[ROLES_CLAIM_KEY];

    setRoles(auth0Roles);
    setIsAdmin(roles.includes('Phone-Log-Admin'));
  }, [getIdTokenClaims, roles]);

  React.useEffect(() => {
    getRoles();
  }, [getRoles]);

  return { isAuthenticated, isLoading, roles, isAdmin };
}
