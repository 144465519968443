import React from 'react';
import { SnackbarProvider } from 'notistack';

import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import SideBar from './SideBar.jsx';
import TopBar from './TopBar.jsx';

const DRAWER_WIDTH = 240;

/**
 * Styled component that contains the main content of the page below the sidebar,
 * acts as a wrapper for the content that gets slid to the right
 * when the side bar is opened
 */
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, mobile }) => ({
    // flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    width: `100%`,
    ...(open &&
      !mobile && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: `${DRAWER_WIDTH}px`,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
      }),
  })
);

function Layout({ children }) {
  // Convert true/false to 1/0 to avoid console errors
  const mobile = useMediaQuery('(max-width:899px)') ? 1 : 0;

  const [open, setOpen] = React.useState(!mobile);

  const handleClick = React.useCallback(() => setOpen((prev) => !prev), []);
  const handleCloseMobile = React.useCallback(() => {
    if (mobile) {
      setOpen(false);
    }
  }, [mobile]);

  return (
    <SnackbarProvider maxSnack={4}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <CssBaseline />
        <TopBar onClick={handleClick} onClose={handleCloseMobile} />
        <Drawer
          sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              border: 'none',
              width: DRAWER_WIDTH,
              boxSizing: 'border-box',
            },
          }}
          variant={mobile ? 'temporary' : 'persistent'}
          ModalProps={{
            keepMounted: true,
          }}
          anchor="left"
          open={open}
          onClose={handleCloseMobile}
        >
          <Toolbar />
          <SideBar onClick={handleCloseMobile} />
        </Drawer>
        <Toolbar />
        <Main open={open} mobile={mobile}>
          {children}
        </Main>
      </LocalizationProvider>
    </SnackbarProvider>
  );
}

export default Layout;
